@tailwind base;
@tailwind components;
@tailwind utilities;



.slick-list {
  padding-bottom: 20px !important;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: white !important;
  font-size: 10px !important;
}
.slick-dots li button:before {
  color: white !important;
  font-size: 10px !important;
}
.text-gradient:hover {
  background: linear-gradient(to right, #E70FAA, #00C0FD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.blinking-cursor {
  font-weight: 100;
  font-size: 1.2em;
  color: black;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}

@-webkit-keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}

@-moz-keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}

@-ms-keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}

@-o-keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}
